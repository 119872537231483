<template>
  <footer class="footer">
    <div class="Community">
      <p>Community & Partnerships</p>
      <div class="iconList">
          <div >
            <img :src="require('@/assets/icon/icon1.svg')" @click="handleClick('https://www.circle.com/en/usdc')" />
            <img :src="require('@/assets/icon/icon2.svg')" @click="handleClick('https://tether.to/')" />
            <img :src="require('@/assets/icon/icon3.svg')" @click="handleClick('https://axieinfinity.com/')" />
            <img :src="require('@/assets/icon/icon4.svg')" @click="handleClick('https://decentraland.org/')" />
            <img :src="require('@/assets/icon/icon5.svg')" @click="handleClick('https://compound.finance/')" />
            <img :src="require('@/assets/icon/icon6.svg')" @click="handleClick('https://www.sushi.com/')" />
            <img :src="require('@/assets/icon/icon7.svg')" @click="handleClick('https://curve.fi/')" />
          </div>
          <div class="icon">
            <img :src="require('@/assets/icon/icon8.svg')" @click="handleClick('https://ethereum.org')" />
            <img :src="require('@/assets/icon/icon9.svg')" @click="handleClick('https://ipfs.io/')" />
            <img :src="require('@/assets/icon/icon10.svg')" @click="handleClick('https://filecoin.io/')" />
            <img :src="require('@/assets/icon/icon11.svg')" @click="handleClick('https://aave.com/')" />
            <img :src="require('@/assets/icon/icon12.svg')" @click="handleClick('https://metamask.io/')" />
            <img :src="require('@/assets/icon/icon13.svg')" @click="handleClick('https://chain.link/')" />
            <img :src="require('@/assets/icon/icon14.svg')" @click="handleClick('https://zksync.io/')" />
          </div>
          <div class="icon">
            <img :src="require('@/assets/icon/icon15.svg')" @click="handleClick('https://arbitrum.io/rollup')" />
            <img :src="require('@/assets/icon/icon16.svg')" @click="handleClick('https://www.optimism.io/')"/>
            <img :src="require('@/assets/icon/icon17.svg')" @click="handleClick('https://polygon.technology/')" />
            <img :src="require('@/assets/icon/icon18.svg')" @click="handleClick('https://zerion.io/')" />
            <img :src="require('@/assets/icon/icon19.svg')" @click="handleClick('https://tornado.dev/')" />
            <img :src="require('@/assets/icon/icon20.svg')" @click="handleClick('https://ens.domains/')" />
            <img :src="require('@/assets/icon/icon21.svg')" @click="handleClick('https://uniswap.org/')" />
          </div>
          <div class="icon">
            <img :src="require('@/assets/icon/icon22.svg')" @click="handleClick('https://superrare.com/')" />
            <img :src="require('@/assets/icon/icon23.svg')" @click="handleClick('https://rarible.com/')" />
            <img :src="require('@/assets/icon/icon24.svg')" @click="handleClick('https://opensea.io/')" />
            <img :src="require('@/assets/icon/icon25.svg')" @click="handleClick('https://www.nbatopshot.com/')" />
            <img :src="require('@/assets/icon/icon26.svg')" @click="handleClick('https://www.cryptokitties.co/')" />
            <img :src="require('@/assets/icon/icon27.svg')" @click="handleClick('https://makerdao.com/en/governance')" />
            <img :src="require('@/assets/icon/icon28.svg')" @click="handleClick('https://www.binance.com/')" />
          </div>
        </div>
      <!-- <div>
        <img src="@/assets/img/computecoin.png" alt="" />
      </div> -->
    </div>
    <div class="hd">
      <div class="logo-wrap">
        <img class="img" :src="require('@/assets/img/ft_logo.png')" />
        <div class="designed">Designed by Pole Labs</div>
      </div>
      <div class="social-contact">
        <img
          :src="require('@/assets/img/telegram.svg')"
          @click="handleClick('https://t.me/revolutionNFT')"
        />
        <img
          :src="require('@/assets/img/social02.svg')"
          @click="handleClick('https://twitter.com/NFTRevo')"
        />
        <img
          :src="require('@/assets/img/reddit.svg')"
          @click="handleClick('https://www.reddit.com/r/RevoNFT/')"
        />
        <img
          :src="require('@/assets/img/youtube.svg')"
          @click="
            handleClick(
              'https://www.youtube.com/channel/UCAVilc5UTUNkVAF3gfeqVIw'
            )
          "
        />
        <img
          :src="require('@/assets/img/social04.svg')"
          @click="handleClick('https://revonft.gitbook.io/')"
        />
        <img
          :src="require('@/assets/img/discord.png')"
          @click="handleClick('https://discord.gg/Ra35WdAKBs')"
        />
      </div>
    </div>
    <!-- <div class="bd">
            <div class="footer-links">
                <dl class="link">
                    <dt>Products</dt>
                    <dd><a>UI Kits</a></dd>
                    <dd><a>Wireframe Kits</a></dd>
                    <dd><a>Icon Sets</a></dd>
                    <dd><a>Themes & Templates</a></dd>
                </dl>
                <dl class="link">
                    <dt>Company</dt>
                    <dd><a>Support</a></dd>
                    <dd><a>Open a Shop</a></dd>
                    <dd><a>Licensing & Terms</a></dd>
                    <dd><a>Technology</a></dd>
                </dl>
                <dl class="link">
                    <dt>Support</dt>
                    <dd><a>Getting started</a></dd>
                    <dd><a>Help center</a></dd>
                    <dd><a>Suggest a feature</a></dd>
                    <dd><a>Report a bug</a></dd>
                </dl>
            </div>
        </div> -->
  
    <div class="copyright">
      &copy; Copyright 2021 Revolution,All rights Reserved
    </div>
  </footer>
</template>
<script>
export default {
  name: "mobileFooter",
  props: {},
  data() {
    return {};
  },
  components: {},
  created() {},
  mounted() {},
  methods: {
    handleClick(url) {
      if(url){
          window.open(url);
        }
    },
  },
};
</script>
<style lang="less" scoped>
.footer {
  background: #0c1a4b;
  color: rgba(255, 255, 255, 0.5);
  padding: 25px 0 0;
  .hd {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    .logo-wrap {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      .designed {
        color: #ccc;
        font-size:13px;
      }
      img {
        width: 105px;
        margin-bottom: 5px;
      }
    }
    .social-contact {
      img {
        width:19px;
        height:19px;
        margin-right:12px;
      }
    }
  }
  .bd {
    padding: 0 25px;
    .footer-links {
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 20px;
      padding: 20px 0 15px;
      font-size: 10px;
      .link {
        dt {
          color: #fff;
          margin: 3px 0;
        }
        dd {
          margin: 3px 0;
        }
      }
    }
  }
  .copyright {
    text-align: center;
    padding: 10px 0 20px;
  }
  .Community {
    border-top: #333e66 1px solid;
    text-align: center;
    color: #fff;
    p {
      margin: 15px 0;
    }
    .iconList{
           display: flex;
          flex-direction: column; 
          margin-left: 30px;
          .icon{
            padding: 15px 0 0 0
          }
          & > div {
          img {
            width: 24px;
            height: 24px;
            margin-right: 24px;
            cursor: pointer;
          }
        }
    }
  }
}
</style>